import React from 'react'
// import img from '../../images/recepcja.jpeg'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'
import img from '../../images/vacum-z-fala-rf.webp'

const VacuumMasaz = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Vacuum Masaż | Modelowanie Sylwetki'
                    description='Jest to zabieg ujędrniający, napinający i uelastyczniający skórę na
        problematycznej partii ciała.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/vacuum-masaz'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Vacuum z Falą Radiową</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Vacuum Masaż zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <h2>Jakie działanie ma zabieg Vacuum z falą RF?</h2>
                                    <p>
                                        Jest to zabieg ujędrniający, napinający i
                                        uelastyczniający skórę na problematycznej partii
                                        ciała.
                                    </p>
                                    <p>
                                        Masaż drenujący dzięki zassaniu oraz podgrzanie
                                        głębokich warstw skóry to połączenie fali
                                        bipolarnej z masażem podciśnieniowym w jednej
                                        głowicy .
                                    </p>
                                    <p>
                                        Podczas działania fali radiowej zachodzi proces
                                        termolizy, który powoduje rozgrzanie naskórka ,
                                        skóry właściwej oraz tkanek podskórnych. Efektem
                                        jest skrócenie włókien kolagenu a także stymulacja
                                        produkcji nowego kolagenu. Dodatkowo podciśnienie
                                        zwiększa przepuszczalność błony komórkowej
                                        adipocytów i wzmaga wytwarzanie włókien
                                        elastynowych w przestrzeni międzykomóekowej
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='content-1'>
                            <div className='section'>
                                <div className='text'>
                                    <h3>Przeciwwskazania:</h3>
                                    <ul>
                                        <li>
                                            Choroby nowotworowe do 5 lat po zakończonym
                                            leczeniu
                                        </li>
                                        <li>Rozrusznik serca</li>
                                        <li>
                                            Implanty – metalowe implanty w okolicy
                                            zabiegowej
                                        </li>
                                        <li>Ciąża i okres laktacji</li>
                                        <li>
                                            Tendencja do krwawienia (choroba wrzodowa
                                            żołądka i dwunastnicy)
                                        </li>
                                        <li>
                                            Wewnętrzne procesy krwotoczne w ostrej fazie
                                        </li>
                                        <li>
                                            Zaburzenia czucia bólu i odczuwania ciepła
                                        </li>
                                        <li>Nadciśnienie</li>
                                        <li>Przerwanie ciągłości skóry</li>
                                        <li>Złote nici, botoks, wypełniacze</li>
                                        <li>Gorączka</li>
                                        <li>Nadczynność tarczycy</li>
                                        <li>Stany zapalne</li>
                                        <li>Obrzęki</li>
                                        <li>Zakrzepowe zapalenie żył i żylaki</li>
                                        <li>Wiek dziecięcy</li>
                                        <li>Zespół Sudecka</li>
                                        <li>Złamania kości, osteoporoza, osteopenia</li>
                                    </ul>
                                    <h3>
                                        Z jakimi zabiegami warto łączyć Vacumm z fala rf?
                                    </h3>
                                    <ul>
                                        <li>Liposukcja Ultradźwiękową</li>
                                        <li>Lipolaserem</li>
                                    </ul>

                                    <h3>Ile zabiegów należy wykonać oraz jak często?</h3>
                                    <p>
                                        Najlepiej wykonać serię 8-10 zabiegów i wykonywać
                                        je 2 razy w tygodniu.
                                    </p>

                                    <h3>
                                        Ile trwa taki zabieg i gdzie można go wykonywać?
                                    </h3>
                                    <p>
                                        Zabieg trwa od 30-40 minut w zależności od partii
                                        ciała
                                    </p>
                                </div>
                                <div className='text'>
                                    <h3>Kto powinien wykonywać ten zabieg?</h3>
                                    <p>Każda osoba, która chce:</p>
                                    <ul>
                                        <li>
                                            Redukować cellulitu, lokalnego nadmiaru tkanki
                                            tłuszczowej
                                        </li>
                                        <li>Wymodelować sylwetki</li>
                                        <li>Odżywić oraz dotlenić skórę</li>
                                        <li>Mieć jędrną oraz elastyczną skórę</li>
                                        <li>Spłycić blizny i zredukować rozstępy</li>
                                        <li>Poprawić krążenie</li>
                                        <li>Usunąć zastoje wody</li>
                                        <li>Zredukować obwody w partii zabiegowej</li>
                                    </ul>

                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>VACUUM Z RF - BRZUCH</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    VACUUM z RF - BRZUCH - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 247 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    VACUUM z RF - BRZUCH -{' '}
                                                    <span>PAKIET 8 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1357 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    VACUUM z RF - BRZUCH -{' '}
                                                    <span>PAKIET 12 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1787 zł
                                                </span>
                                            </li>
                                        </ul>
                                        <h2>VACUUM Z RF - UDA, POŚLADKI</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    VACUUM z RF - UDA, POŚLADKI -
                                                    POJEDYNCZY ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 297 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    VACUUM z RF - UDA, POŚLADKI -{' '}
                                                    <span>PAKIET 8 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 1497 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    VACUUM z RF - UDA, POŚLADKI -{' '}
                                                    <span>PAKIET 12 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 1887 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default VacuumMasaz
